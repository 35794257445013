export enum HttpStatusCode {
    Ok = 200,
    Fail = 500,
    BadRequest = 400,
    Unauthorized = 401
}
export enum HttpMethod {
    POST = "POST",
    GET = "GET",
    DELETE = "DELETE",
    FETCH = "FETCH",
    PUT = "PUT"
}
export enum ForumType {
    Qa = "QA",
    Notice = "Notice",
    Help = "Help"
}
