import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PagenotFound from './PagenotFound';
import {Home} from '../pages';
import DownloadPage from '../pages/start/Download'

if (process.env.REACT_APP_VAR !== 'development') {
    console.log = function no_console() {
    };
}

class App extends Component {
    render() {
        return (
            <Router>
                <div className="page-container">
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route path="/start/download" component={DownloadPage}/>
                        <Route path='*' component={PagenotFound}/>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;


