import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageInfo } from '../model/PageInfo'


//* 초기 상태
const initialState: PageInfo = {
    name:''
};

const title = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setModel(state, action: PayloadAction<PageInfo>) {
            state = {
                ...state,
                name: action.payload.name
            };
            return state;
        },
        //* 초기화 하기
        init(state) {
            state = initialState;
            return state;
        }
    }
});

export const titleActions = {...title.actions};

export default title;
