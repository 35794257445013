import React from 'react';
import {Container} from 'react-bootstrap';

const PagenotFound = () => {
    return (
        <Container className="bloc-sm">
            <div className="row">
                <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3">
                    <div className="card"><img src="/img/lazyload-ph.png" data-src="/img/image.png"
                                               className="img-fluid mx-auto d-block img-style lazyload"
                                               alt="placeholder user"/>
                        <div className="card-body">
                            <h3 className="mg-md text-center title3 h3-style">404</h3>
                            <h6 className="mg-md text-center title6 text-lg-center lr">찾을 수 없는 페이지입니다.<br/>요청하신 페이지가
                                사라졌거나, 잘못된 경로를 이용하셨습니다.&nbsp;<br/></h6>
                            <div className="text-center text-lg-center">
                                <a href="/"
                                   className="btn btn-d bt btn-lg">홈으로 이동</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
export default PagenotFound;
