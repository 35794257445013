import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as device from 'react-device-detect';

const DownloadPage = (props:RouteComponentProps) => {
    const navigate = useHistory();
    const isAndroid = device.isAndroid;
    const isiOS = device.isIOS || device.isIOS13 || device.isIPhone13 || device.isIPad13;

    const iosLink = `jstamp://main`;
    const appleAppId = process.env.REACT_APP_APPLE_PACKAGE;
    const iosMarketUrl = `https://itunes.apple.com/kr/app/apple-store/${appleAppId}?mt=8`;

    const androidPackage = process.env.REACT_APP_ANDROID_PACKAGE;
    const androidLink = `intent://main?act=download#Intent;scheme=JStamp;package=${androidPackage};end`;
    // const androidLink = "intent://details?id=com.boxtree.jstamp.activity#Intent;scheme=jstamp;end";const androidLink = "intent://main?act=download#Intent;scheme=jstamp;package=com.boxtree.jstamp.activity;end;";

    const goStore = () => {
        if (isAndroid) {
            window.location.replace(androidLink);
        } else if (isiOS) {
            setTimeout(function () {
                const ans = window.confirm("앱스토어로 이동하시겠습니까?");
                if (ans === true) {
                    window.location.replace(iosMarketUrl);
                }
            }, 2000);
            window.location.replace(iosLink);

        } else {
            // setMsg(`아이폰 또는 안드로이드폰에서만 실행이 가능합니다.<br />고객님의 접속기기는 [${device.deviceType}] 입니다.`);
            // window.location.replace('/');
            navigate.push("/");
        }
    }


    useEffect(()=>{
        goStore();
    });
    return (
        <>
            <img src="/img/app_download_loading.png"  className={"app_download"} onClick={() => goStore()} alt={"app_download_loading"} />
        </>
    )
};

export default DownloadPage;
