import React, { useRef } from 'react'
import { Carousel, Accordion, Col, Row, Ratio } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Autoplay } from 'swiper'
import Image from 'react-bootstrap/Image';
// import { BsFillCheckCircleFill, FaFacebookF, FaInstagram, RiKakaoTalkFill } from 'react-icons/all'
import ApplyHtml from './apply/ApplyHtml'
import NavBar from '../component/NavBar'
import LoadingHtml from '../shared/Loading'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import { FaInstagram } from 'react-icons/fa'
import { RiKakaoTalkFill } from 'react-icons/ri'
const scrollToRef = (ref:any) => window.scrollTo(0, ref.current.offsetTop);

const Home = () => {
    const ApplyWrap = useRef(null);
    const handleTopApplyWrap = () => scrollToRef(ApplyWrap);

    return (
        <div>
            <NavBar />

            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 d-img"
                        src="/img/www/Artboard1.jpg"
                        alt="First slide"
                    />
                    <img
                        className="d-md-none w-100"
                        src="/img/www/Artboard1_m.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className={"carousel-caption"}>
                        <h1 className={"carousel-txt"}>
                            종이스탬프의 감성을<br/>서비스에 담았습니다.<br/>
                        </h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 d-img"
                        src="/img/www/Artboard2.jpg"
                        alt="Second slide"
                    />
                    <img
                        className="d-md-none w-100"
                        src="/img/www/Artboard2_m.jpg"
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h1 className={"carousel-txt"}>
                            더 쉽게. 더 재밌게. 더 스마트하게<br/>단골을 만드는 가장 빠른 솔루션<br/>
                        </h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block  w-100 d-img"
                        src="/img/www/Artboard3.jpg"
                        alt="Third slide"
                    />
                    <img
                        className="d-md-none w-100"
                        src="/img/www/Artboard3_m.jpg"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h1 className={"carousel-txt"}>
                            전문적인 고객관리와 마케팅<br/>이젠 가능합니다.<br/>
                        </h1>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <div className={"sector-wrap"}>
                <div className={"container bloc-md effect-container"}>
                    <Row>
                        <Col lg={6} md={12}>
                            <div>
                                <h6 className="mg-md subtitle-u animated fadeIn" data-appear-anim-style="fadeIn">
                                    MEMBERSHIP
                                </h6>
                                <h1 className="mg-md float-lg-none title animated fadeIn" data-appear-anim-style="fadeIn">
                                    <span className="title_bold">디지털 스탬프</span>로 시작하는<br/>스마트 한 고객관리<br/>
                                </h1>
                                <h3 className="mg-md description animated fadeInDown none " data-appear-anim-style="fadeInDown">
                                    고객 스마트폰 화면에 바로 스탬핑해주세요!<br/>디지털 스탬프를 이용하여 적립부터 <br/>모바일 상품권 사용까지 모든 게 해결됩니다.<br/>
                                </h3>
                            </div>
                        </Col>
                        <Col lg={6}  md={12}>
                            <div className="effect_anigif">
                                <img className="img-fluid animated fadeIn lazyload"
                                     src="/img/www/lazyload-ph.png"
                                     data-src="/img/www/ani01.gif"
                                     alt="ani01"
                                     data-appear-anim-style="fadeIn" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"sector-wrap"} >
                <div className={"container bloc-md effect-container"}>
                    <Row>
                        <Col lg={6} md={12}>
                            <div>
                                <h6 className="mg-md subtitle-u animated fadeIn" data-appear-anim-style="fadeIn">
                                    CROSS SELLING
                                </h6>
                                <h1 className="mg-md float-lg-none title animated fadeIn" data-appear-anim-style="fadeIn">
                                    주변 매장과 협업해서<br/><span className="title_bold">신규 고객 확보</span><br/>
                                </h1>
                                <h3 className="mg-md description animated fadeInDown" data-appear-anim-style="fadeInDown">
                                    교차 쿠폰 서비스로 매장과 매장을 연결합니다. <br/>이제 지역 내 사장님들과 파트너가 되어 <br/>신규고객을 유치하세요.<br/>
                                </h3>
                            </div>
                        </Col>
                        <Col lg={6}  md={12}>
                            <div className="effect_anigif">
                                <img className="img-fluid animated fadeIn lazyload"
                                     src="/img/www/lazyload-ph.png"
                                     data-src="/img/www/ani02.gif"
                                     alt="ani02"
                                     data-appear-anim-style="fadeIn" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"sector-wrap"}>
                <div className={"container bloc-md effect-container"}>
                    <Row>
                        <Col lg={6} md={12}>
                            <div>
                                <h6 className="mg-md subtitle-u animated fadeIn" data-appear-anim-style="fadeIn">
                                    LOCAL STAMP TOUR
                                </h6>
                                <h1 className="mg-md float-lg-none title animated fadeIn" data-appear-anim-style="fadeIn">
                                    <span className="title_bold">스탬프 투어</span>로 고객들에게<br/>다양한 서비스를 제공<br/>
                                </h1>
                                <h3 className="mg-md description animated fadeInDown" data-appear-anim-style="fadeInDown">
                                    스탬프 투어로 단골 고객들에게 로컬의 다양한 <br/>핫 플레이스를 추천하고 고객 만족도를 높여보세요.<br/>지역 내 사장님들과 함께 대기업 프랜차이즈와
                                    경쟁이<br/>가능해집니다.<br/>
                                </h3>
                            </div>
                        </Col>
                        <Col lg={6}  md={12}>
                            <div className="effect_anigif">
                                <img className="img-fluid animated fadeIn lazyload"
                                     src="/img/www/lazyload-ph.png"
                                     data-src="/img/www/ani03.gif"
                                     alt="ani03"
                                     data-appear-anim-style="fadeIn" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"sector-wrap"}>
                <div className={"container bloc-md effect-container"}>
                    <Row>
                        <Col lg={6} md={12}>
                            <div>
                                <h6 className="mg-md subtitle-u animated fadeIn" data-appear-anim-style="fadeIn">
                                    MOBILE VOUCHER
                                </h6>
                                <h1 className="mg-md float-lg-none title animated fadeIn" data-appear-anim-style="fadeIn">
                                    매장전용 <span className="title_bold">모바일 상품권</span>으로<br/>매출 향상<br/>
                                </h1>
                                <h3 className="mg-md description animated fadeInDown" data-appear-anim-style="fadeInDown">
                                    대기업 프랜차이즈만 해오던 모바일 상품권 서비스를 <br/>이젠 소상공매장에서도 가능합니다.<br/>매장전용 모바일 상품권을 만들어서 더 많은
                                    수익을 <br/>창출하세요.<br/>
                                </h3>
                            </div>
                        </Col>
                        <Col lg={6}  md={12}>
                            <div className="effect_anigif">
                                <img className="img-fluid animated fadeIn lazyload"
                                     src="/img/www/lazyload-ph.png"
                                     data-src="/img/www/ani04.gif"
                                     alt="ani04"
                                     data-appear-anim-style="fadeIn" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"sector-wrap"}>
                <div className={"container bloc-md effect-container"}>
                    <Row>
                        <Col lg={6} md={12}>
                            <div>
                                <h6 className="mg-md subtitle-u animated fadeIn" data-appear-anim-style="fadeIn">
                                    DELIVERY STAMP
                                </h6>
                                <h1 className="mg-md float-lg-none title animated fadeIn" data-appear-anim-style="fadeIn">
                                    <span className="title_bold">배달 스탬프</span>로 매장이<br/>고객에게 직접 혜택 제공<br/>
                                </h1>
                                <h3 className="mg-md description animated fadeInDown" data-appear-anim-style="fadeInDown">
                                    배달의민*, 요기*를 통한 배달 서비스 만족하시나요? <br/>이제 배달 고객도 직접 관리하고 주문도 직접 받으세요.<br/>쿠폰북과 자석 스티커는 더
                                    이상 필요 없습니다.<br/>신개념 적립서비스가 곧 출시됩니다. <br/>* 서비스 예정<br/>
                                </h3>
                            </div>
                        </Col>
                        <Col lg={6}  md={12}>
                            <div className="effect_anigif">
                                <img className="img-fluid animated fadeIn lazyload"
                                     src="/img/www/lazyload-ph.png"
                                     data-src="/img/www/ani05.gif"
                                     alt="ani05"
                                     data-appear-anim-style="fadeIn" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"sector-wrap wh-bg vd-wrap"}>
                <div className={"container mx-full"}>
                    <Row>
                        <Col>
                            <Ratio aspectRatio="16x9">
                                <iframe title="vd"
                                        className="iframe-wrap"
                                        src="https://player.vimeo.com/video/399816811"
                                        frameBorder="0" allow="autoplay; fullscreen"></iframe>
                            </Ratio>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"jstamp-desc-wrap "} id="jstamp-desc-wrap">
                <div className={"container default-container"}>
                    <Row className={"title-section"}>
                        <Col className={"animated fadeInDown"} data-appear-anim-style="fadeInDown">
                            <h1 className="title text-center">
                                매장과 고객을 위한 쉬운연결
                            </h1>
                            <h6 className="descript text-center ">
                                제이스탬프라면 가능합니다!
                            </h6>
                        </Col>
                    </Row>
                    <Row className={"cont-section"}>
                        <Col lg={4} md={12}>
                            <Image
                                className="img-fluid mx-auto d-block lazyload"
                                src="/img/www/Group%20171.jpg"
                                alt="placeholder image"
                                data-appear-anim-style="fadeIn" />
                            <h5 className="mg-md text-sm-center text-lg-left connection-title">
                                지역상권과의 협업
                            </h5>
                            <h6 className="mg-md text-sm-center text-lg-left connection-description">
                                신규 오픈 후 걱정이 많으시죠? &nbsp;입지가 안 좋아 항상 고민이신가요? &nbsp;지역 내 매장들과의 협업을 도와드립니다.
                            </h6>
                        </Col>
                        <Col lg={4} md={12}>
                            <Image src="/img/www/Group%20181.jpg"
                                 className="img-fluid mx-auto d-block lazyload" alt="placeholder image" />
                            <h5 className="mg-md text-sm-center text-lg-left connection-title">
                                단골 고객 확보에 최적화
                            </h5>
                            <h6 className="mg-md text-sm-center text-lg-left connection-description">
                                1명의 단골고객이 5명의 신규고객을 데려옵니다. &nbsp;자연스럽게 단골고객을 확보하세요.
                            </h6>
                        </Col>
                        <Col lg={4} md={12}>
                            <Image src="/img/www/Group%20191.jpg"
                                 className="img-fluid mx-auto d-block lazyload" alt="placeholder image" />
                            <h5 className="mg-md text-sm-center text-lg-left connection-title">
                                다양한 프로모션 진행
                            </h5>
                            <h6 className="mg-md text-sm-center text-lg-left connection-description">
                                소상공인 매장도 대기업 프랜차이즈와 같은 다양한 고객 프로모션을 할 수 있도록 지원합니다.
                            </h6>
                        </Col>
                    </Row>
                    <Row className={"cont-section"}>
                        <Col lg={4} md={12}>
                            <Image src="/img/www/Group%20221.jpg"
                                 className="img-fluid mx-auto d-block lazyload" alt="placeholder image" />
                            <h5 className="mg-md text-sm-center text-lg-left connection-title">
                                지속적인 매출 상승
                            </h5>
                            <h6 className="mg-md text-sm-center text-lg-left connection-description">
                                모바일 상품권, &nbsp;스탬프 투어 등의 다양한 서비스를 통해 매출이 상승합니다.
                            </h6>
                        </Col>
                        <Col lg={4} md={12}>
                            <Image src="/img/www/Group%20211.jpg"
                                 className="img-fluid mx-auto d-block lazyload" alt="placeholder image" />
                            <h5 className="mg-md text-sm-center text-lg-left connection-title">
                                고객 재방문률 상승
                            </h5>
                            <h6 className="mg-md text-sm-center text-lg-left  connection-description">
                                한번 방문한 고객의 재방문률을 높이기 위한 방법을 제공합니다.
                            </h6>
                        </Col>
                        <Col lg={4} md={12}>
                            <Image src="/img/www/Group%20201.jpg"
                                 className="img-fluid mx-auto d-block lazyload" alt="placeholder image" />
                            <h5 className="mg-md text-sm-center text-lg-left connection-title">
                                안정적이고 검증된 고객관리 시스템
                            </h5>
                            <h6 className="mg-md text-sm-center text-lg-left  connection-description">
                                최고의 소프트웨어 품질 (GS 1등급)로 검증된 고객관리 시스템을 도입해보세요
                            </h6>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"incomm-wrap top_line"}>
                <div className={"container default-container"}>
                    <Row className={"title-section"}>
                        <Col className={"animated fadeInDown"} data-appear-anim-style="fadeInDown">
                            <h1 className="title text-center">
                                도입 사례
                            </h1>
                            <h6 className="descript text-center ">
                                제이스탬프 도입 후 달라진 매장의 변화, 이젠 사장님이 경험하실 차례입니다.
                            </h6>
                        </Col>
                    </Row>
                    <div className={"swiper-container"}>
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            slidesPerView={1}
                            slidesPerGroup={1}
                            spaceBetween={50}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            pagination={{ clickable: true }}
                            className={"mySwiper"}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                    slidesPerGroup: 3,
                                },
                            }}
                        >
                                <SwiperSlide>
                                    <div className={'swiper-box'}>
                                        <Image src="/img/www/store01.jpg"
                                             className="img-fluid mx-auto d-block interview_images swiper-lazy"
                                             alt="placeholder image" />
                                        <div className="interview_zone">
                                            <div className="card-body">
                                                <div
                                                    className="form-group text-lg-right label_section text-sm-right text-right">
                                                    <label className="label_store">
                                                        적립
                                                    </label>
                                                    <label className="label_store">
                                                        투어
                                                    </label>
                                                    <label className="label_store">
                                                        모바일상품권
                                                    </label>
                                                </div>
                                                <h5 className="interview-title">
                                                    “주변매장 사장님들과의 교차쿠폰 서비스는 기대 이상였어요”
                                                </h5>
                                                <p className="interview-description">
                                                    제이스탬프 도입 후 매장의 단골이 정확히 몇 명인지 어떤 패턴으로 방문하는지 명확하게 알게 되었어요. 더 이상 늘어날 것 같지
                                                    않았던 신규고객들도 주변 매장과의 교차쿠폰 서비스를 통해 증가하는 걸 보고 기대이상의 효과를 누리고 있습니다.
                                                </p>
                                                <h6 className="mg-md user-name">
                                                    By 커피파인더
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'swiper-box'}>
                                        <Image src="/img/www/store02.jpg"
                                             className="img-fluid mx-auto d-block interview_images swiper-lazy"
                                             alt="placeholder image" />
                                        <div className="interview_zone">
                                            <div className="card-body">
                                                <div
                                                    className="form-group text-lg-right label_section text-sm-right text-right">
                                                    <label className="label_store">
                                                        적립
                                                    </label>
                                                    <label className="label_store">
                                                        투어
                                                    </label>
                                                </div>
                                                <h5 className="interview-title">
                                                    “스탬프투어 참여이후 이젠 고객들이 알아서 찾아와 줍니다”
                                                </h5>
                                                <p className="interview-description">
                                                    구도심에 위치한 간판도 없는 작은 카페였습니다. 부부가 운영하다 보니 매장운영도 벅찬 나머지 마케팅을 꿈도 못 꾸고 있었죠.
                                                    제이스탬프를 도입하고 ‘카페 스탬프투어’에 참여했더니 자연스레 신규 고객이 늘고 매출도 3배 이상 증가했습니다.
                                                </p>
                                                <h6 className="mg-md user-name">
                                                    By 컴플렉스 커피
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'swiper-box'}>
                                        <Image src="/img/www/store03.jpg"
                                             className="img-fluid mx-auto d-block interview_images swiper-lazy"
                                             alt="placeholder image" />
                                        <div className="interview_zone">
                                            <div className="card-body">
                                                <div
                                                    className="form-group text-lg-right label_section text-sm-right text-right">
                                                    <label className="label_store">
                                                        적립
                                                    </label>
                                                    <label className="label_store">
                                                        투어
                                                    </label>
                                                    <label className="label_store">
                                                        모바일상품권
                                                    </label>
                                                </div>
                                                <h5 className="interview-title">
                                                    “나이 많은 고객들도 어렵지 않게 사용합니다”
                                                </h5>
                                                <p className="interview-description">
                                                    200석이 넘는 큰 규모의 고기집을 운영하다 보니 다양한 마케팅을 시도해보았지만 큰 효과를 누리지 못했는데, 제이스탬프는
                                                    ‘도장’이라는 전통적인 적립방식을 이용하니 재미있게 고객을 모을 수 있었습니다.
                                                </p>
                                                <h6 className="mg-md user-name">
                                                    By 태백산 제주 본점
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'swiper-box'}>
                                        <Image src="/img/www/store04.jpg"
                                             className="img-fluid mx-auto d-block interview_images"
                                             alt="placeholder image" />
                                        <div className="interview_zone">
                                            <div className="card-body">
                                                <div
                                                    className="form-group text-lg-right label_section text-sm-right text-right">
                                                    <label className="label_store">
                                                        적립
                                                    </label>
                                                    <label className="label_store">
                                                        투어
                                                    </label>
                                                    <label className="label_store">
                                                        모바일상품권
                                                    </label>
                                                </div>
                                                <h5 className="interview-title">
                                                    “모바일 상품권으로 다양한 부가 매출이 생겼습니다.”
                                                </h5>
                                                <p className="interview-description">
                                                    아파트 상가에 위치해 있다 보니 주부층의 고객과 주변 학원 선생님 고객들이 많습니다. 우선 모바일 스탬프 적립 시스템을 도입한
                                                    이후 매장과 고객이 둘 다 만족도가 오른게 사실입니다. 거기에 다양한 모바일 상품권까지 고객들에게 판매 할 수 있어서 부가
                                                    매출까지 늘었습니다.
                                                </p>
                                                <h6 className="mg-md user-name">
                                                    By 커피 코알라
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'swiper-box'}>
                                        <Image src="/img/www/store05.jpg"
                                             className="img-fluid mx-auto d-block interview_images"
                                             alt="placeholder image" />
                                        <div className="interview_zone">
                                            <div className="card-body">
                                                <div
                                                    className="form-group text-lg-right label_section text-sm-right text-right">
                                                    <label className="label_store">
                                                        적립
                                                    </label>
                                                    <label className="label_store">
                                                        투어
                                                    </label>
                                                </div>
                                                <h5 className="interview-title">
                                                    “플리 마켓 내 푸드트럭과도 이벤트를 같이하고 있어요”
                                                </h5>
                                                <p className="interview-description">
                                                    수목원 내에 위치한 카페를 운영하다 보니 다양한 플리마켓 셀러들과 푸드트럭 사장님들과의 콜라보를 시도해보 싶었어요. 제이스탬프를
                                                    이미 쓰고 있었는데 푸드트럭 사장님들과 연계한 소규모 스탬프투어를 만들어 주셨어요. 고객들의 반응도 너무 좋습니다.
                                                </p>
                                                <h6 className="mg-md user-name">
                                                    By 볕이 드는 곳, 벧디
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'swiper-box'}>
                                        <Image src="/img/www/store06.jpg"
                                             className="img-fluid mx-auto d-block interview_images"
                                             alt="placeholder image" />
                                        <div className="interview_zone">
                                            <div className="card-body">
                                                <div
                                                    className="form-group text-lg-right label_section text-sm-right text-right">
                                                    <label className="label_store">
                                                        적립
                                                    </label>
                                                    <label className="label_store">
                                                        투어
                                                    </label>
                                                    <label className="label_store">
                                                        모바일상품권
                                                    </label>
                                                </div>
                                                <h5 className="interview-title">
                                                    “재미있는 멤버십 관리로 회원들의 재방문률이 올라갑니다”
                                                </h5>
                                                <p className="interview-description">
                                                    휘트니스 클럽에서 기존에 사용하고 있던 고객관리 시스템은 고객 대상 이벤트를 진행하기에는 한계가 많습니다. 제이스탬프를 도입하고
                                                    ‘출첵이벤트’를 진행회원들의 클럽 방문률이 눈에 띄게 올랐습니다. 제휴매장들의 쿠폰을 제공하는 방식도 스마트해져서 편리하게
                                                    이용하고 있습니다.
                                                </p>
                                                <h6 className="mg-md user-name">
                                                    By 마인드 휘트니스
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                        </Swiper>

                    </div>

                </div>
            </div>

            <div className={"store-logo-wrap "}>
                <div className={"container default-container"}>
                    <Image id="store_logo_image" className={"store_logo_image"} src="/img/www/store_logos.jpg" />
                </div>
            </div>

            <div className={"review-wrap top_line"}>
                <div className={"container default-container"}>
                    <Row className={"title-section"}>
                        <Col className={"animated fadeInDown"} data-appear-anim-style="fadeInDown">
                            <h1 className="title text-center">
                                이용 후기
                            </h1>
                            <h6 className="descript text-center ">
                                회원분들이 직접 작성한 리얼 후기를 확인 해 보세요.
                            </h6>
                        </Col>
                    </Row>
                    <div className={"swiper-container"}>
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            slidesPerView={1}
                            slidesPerGroup={1}
                            spaceBetween={50}
                            // loop={true}
                            // loopFillGroupWithBlank={true}
                            pagination={{ clickable: true }}
                            // navigation={true}
                            className={"mySwiper"}
                            breakpoints={{
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                    slidesPerGroup: 3,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <div className={'swiper-box'}>
                                    <Image src="/img/www/review01.jpg"
                                           className="img-fluid mx-auto d-block review-images swiper-lazy"
                                           alt="placeholder image" />
                                    <div className="review_zone">
                                        <div className="card-body">
                                            <h5 className="mg-md interview-title">
                                                “카페 스탬프투어는 1년 내내 하면 안되나요?”
                                            </h5>
                                            <p className="interview-description">
                                                제이스탬프 덕분에 제주에서의 카페 라이프가 즐거워졌습니다. 지도로 하던 스탬프투어의 감성을 그대로 담아 만든 ‘카페 스탬프투어’는
                                                커피 매니아인 저에게 최고의 이벤트였습니다. ‘카페 스탬프투어’는 1년 내내 해주시면 안되나요?
                                            </p>
                                            <h6 className="mg-md user-name">
                                                ID: han****
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={'swiper-box'}>
                                    <Image src="/img/www/review02.jpg"
                                           className="img-fluid mx-auto d-block review-images swiper-lazy"
                                           alt="placeholder image" />
                                    <div className="review_zone">
                                        <div className="card-body">
                                            <h5 className="mg-md interview-title">
                                                “카페 스탬프투어는 1년 내내 하면 안되나요?”
                                            </h5>
                                            <p className="interview-description">
                                                제이스탬프 덕분에 제주에서의 카페 라이프가 즐거워졌습니다. 지도로 하던 스탬프투어의 감성을 그대로 담아 만든 ‘카페 스탬프투어’는
                                                커피 매니아인 저에게 최고의 이벤트였습니다. ‘카페 스탬프투어’는 1년 내내 해주시면 안되나요?
                                            </p>
                                            <h6 className="mg-md user-name">
                                                ID: han****
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={'swiper-box'}>
                                    <Image src="/img/www/review03.jpg"
                                           className="img-fluid mx-auto d-block review-images swiper-lazy"
                                           alt="placeholder image" />
                                    <div className="review_zone">
                                        <div className="card-body">
                                            <h5 className="mg-md interview-title">
                                                “동네 카페 사장님과 친구가 되었어요”
                                            </h5>
                                            <p className="interview-description">
                                                전화번호만 누르고 적립 받는 서비스만 쓰다가 제이스탬프 쓰는 매장을 가니 좀 신선했어요. 스탬프를 찍어주는 동안 사장님이 커피맛은
                                                어떤지 , 신 메뉴 나왔는데 맛 좀 보지 않겠냐는 등 얘기를 나누다가 어느새 친구가 되었어요 ㅎㅎ
                                            </p>
                                            <h6 className="mg-md user-name">
                                                ID: entre.mo***
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                </div>
            </div>

            <div className={"service-charge-wrap top_line"} id={"service-charge-wrap"}>
                <div className={"container default-container"}>
                    <Row className={"title-section"}>
                        <Col className={"animated fadeInDown"} data-appear-anim-style="fadeInDown">
                            <h1 className="title text-center">
                                서비스 요금제
                            </h1>

                        </Col>
                    </Row>
                    <Row className={"cont-section"}>
                        <Col xs={2} className={"d-md-block  d-none"}>
                            <div className="card chage-box card_mok">
                                <div className="card-header card_header_bgcolor">
                                    <h6 className="box-descript">
                                        {/*혜택이 큰 2년약정*/}
                                    </h6>
                                    <h3 className="box-title">
                                        {/*SMART*/}
                                    </h3>
                                </div>
                                <div className="card-body pay_board">
                                    <ul className="list-unstyled">
                                        <li>
                                            <h6 className="pay-title">
                                                {/*<span>1,000,000원/2년</span>*/}
                                            </h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body pay-detail" >
                                    <ul className="list-unstyled mok_list">
                                        <li>
                                            <p className="text-right">
                                                적립 회원 수
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                지점(지원매장)
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                상품권 발행
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                대시보드
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                통계분석
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                푸시&amp;알림톡
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                투어
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                교차쿠폰
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                매장 POP set
                                            </p>
                                        </li>
                                        <li>
                                            <p className="text-right">
                                                디지털 스탬프
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={12}>
                            <div className="card chage-box basic-price">
                                <div className="card-header card_header_bgcolor">
                                    <h6 className="box-descript">
                                        부담없는 1년약정
                                    </h6>
                                    <h3 className="box-title">
                                        BASIC
                                    </h3>
                                </div>
                                <div className="card-body pay_board">
                                    <ul className="list-unstyled">
                                        <li>
                                            <h6 className="pay-title">
                                                <span>600,000원/1년</span>
                                            </h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body pay-detail" >
                                    <div className="d-md-block  d-none">
                                        <ul className="list-unstyled">
                                            <li>
                                                <p className="text-center">
                                                    5,000명
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    1개 이하
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    제
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    1세트 제공
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    50,000원/개당
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row  d-md-none d-flex">
                                        <div className="col">
                                            <ul className="list-unstyled mok-list-right">
                                                <li>
                                                    <p className="mx-auto d-block text-lg-right text-sm-right text-right">
                                                        적립 회원 수
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right mx-auto d-block text-sm-right text-right">
                                                        지점(지원매장)
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        상품권 발행
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        대시보드
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        통계분석
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        푸시&amp;알림톡
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        투어
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right mx-auto d-block text-right">
                                                        교차쿠폰
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        매장 POP set
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        디지털 스탬프
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col list_left">
                                            <ul className="list-unstyled list_left">
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        5,000명
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        1개 이하
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        1세트 제공
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        50,000원/개당
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={12}>
                            <div className="card chage-box smart-price">
                                <div className="card-header card_header_bgcolor">
                                    <h6 className="box-descript">
                                        혜택이 큰 2년약정
                                    </h6>
                                    <h3 className="box-title">
                                        SMART
                                    </h3>
                                </div>
                                <div className="card-body pay_board">
                                    <ul className="list-unstyled">
                                        <li>
                                            <h6 className="pay-title">
                                                <span>1,000,000원/2년</span>
                                            </h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body pay-detail" >
                                    <div className="d-md-block  d-none">
                                        <ul className="list-unstyled">
                                            <li>
                                                <p className="text-center">
                                                    무제한
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    2개 이하
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    <BsFillCheckCircleFill className={"icon-color"}/>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    2세트 지원
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text-center">
                                                    2개 무상 제공
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="row  d-md-none d-flex">
                                        <div className="col">
                                            <ul className="list-unstyled mok-list-right">
                                                <li>
                                                    <p className="mx-auto d-block text-lg-right text-sm-right text-right">
                                                        적립 회원 수
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right mx-auto d-block text-sm-right text-right">
                                                        지점(지원매장)
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        상품권 발행
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        대시보드
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        통계분석
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        푸시&amp;알림톡
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        투어
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right mx-auto d-block text-right">
                                                        교차쿠폰
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        매장 POP set
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-lg-right text-sm-right text-right">
                                                        디지털 스탬프
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col list_left">
                                            <ul className="list-unstyled list_left">
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        5,000명
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        1개 이하
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        <BsFillCheckCircleFill className={"icon-color"}/>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        1세트 제공
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text-sm-left text-left">
                                                        50,000원/개당
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={12} className={"double-box"}>
                            <div className="card chage-box">
                                <div className="card-header card_header_bgcolor">
                                    <h6 className="box-descript">
                                        특별한 제안
                                    </h6>
                                    <h3 className="box-title">
                                        제주 프로모션
                                    </h3>
                                </div>
                                <div className="card-body pay_board">
                                    <ul className="list-unstyled">
                                        <li>
                                            <h6 className="pay-title">
                                                <span>별도 문의</span>
                                            </h6>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card chage-box">
                                <div className="card-header card_header_bgcolor">
                                    <h6 className="box-descript">
                                        지점운영
                                    </h6>
                                    <h3 className="box-title">
                                        프렌차이즈
                                    </h3>
                                </div>
                                <div className="card-body pay_board">
                                    <ul className="list-unstyled">
                                        <li>
                                            <h6 className="pay-title">
                                                <span>별도 문의</span>
                                            </h6>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row className={"cont-section"}>
                        <Col>
                                <p className="etc-txt">
                                    * VAT별도<br/> * 매장용 POP SET : 테이블 배너, QR 목걸이, QR 명함, 스탬프 거치대<br/>
                                </p>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={"service-apply-wrap"} id={"service-apply-wrap"} ref={ApplyWrap}>
                <div className={"container default-container"}>
                    <Row className={"title-section"}>
                        <Col className={"animated fadeInDown"} data-appear-anim-style="fadeInDown">
                            <h1 className="title text-center">
                                서비스 도입문의
                            </h1>
                            <h6 className="descript text-center ">
                                도입문의부터 계약, 운영까지 전문 컨설턴트가 친절히 안내해드립니다.
                            </h6>
                        </Col>
                    </Row>
                    <div className={"cont-section"}>
                        <Row>
                            <Col lg={{span:6, offset: 3}} md={12}>
                                <ApplyHtml />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className={"customer-service-wrap "} id={"customer-service-wrap"}>
                <div className={"container default-container"}>
                    <Row className={"title-section"}>
                        <Col className={"animated fadeInDown"} data-appear-anim-style="fadeInDown">
                            <h1 className="title text-center">
                                고객센터
                            </h1>
                        </Col>
                    </Row>
                    <Accordion flush>
                        <Row className={"cont-section"}>
                            <Col lg={6} md={12}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>제이스탬프 적립 서비스는 모바일 앱을 설치해야 하나요?</Accordion.Header>
                                    <Accordion.Body>
                                        제이스탬프는 모바일 앱 기반의 멤버십 서비스 입니다.<br/>고객들이 구글플레이 또는 앱스토어에서 ‘제이스탬프’를 다운받아 설치해야<br/>
                                        멤버십 서비스가 가능합니다.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>왜 2.0 업데이트 이후 로그인을 해야하나요?</Accordion.Header>
                                    <Accordion.Body>
                                        핸드폰 변경이나 분실시 적립내용이나 선불쿠폰 사용이력 등의 추적이 안되어 발생되는 고객의 불편이나 불이익을 방지하기 위하여 제이스탬프 2.0에서는 소셜 로그인을 진행하도록 하였습니다.<br/> 제이스탬프의 소셜 로그인은 핸드폰 변경이나 분실시 기존 사용 이력 추적이나 쿠폰 등 데이터 이전용으로만 사용됩니다.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>서비스 가맹 시 매장에서 제공받는 물품은?</Accordion.Header>
                                    <Accordion.Body>
                                        제이스탬프 가맹점이 되시면 매장전용 디지털 스탬프, POP, 명함쿠폰 등을 지원해드립니다.자세한 사항은 카카오톡에서 ‘ 제이스탬프&rsquo; 검색 후 채널을 추가하셔서 확인주세요.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>모바일 상품권 서비스란 무엇인가요?</Accordion.Header>
                                    <Accordion.Body>
                                        소상공매장이 직접 발행 및 관리 할 수 있는 상품권 서비스 입니다.<br/>정기권(차감형)과 정액권(금액형) 2가지로 운영 가능합니다.<br/>궁금하신 점은 cs@boxtree.kr 로 문의주세요.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>저희 매장의 고객현황을 알 수 있나요?</Accordion.Header>
                                    <Accordion.Body>
                                        제이스탬프의 가맹점이 되시면 ‘매장별 관리페이지’를 만들고 이를 관리할 수 있는 ID/PW를 알려 드립니다.<br/>전체 고객현황, 특정기간 내 고객 증감, 적립쿠폰 발행 수, 현물 사용 현황등 다양한정보를 제공하며 이를 기반으로 타겟 마케팅이 가능합니다.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Col>
                            <Col lg={6} md={12}>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>회원 탈퇴는 어떻게 하나요?</Accordion.Header>
                                    <Accordion.Body>
                                        회원 탈퇴시 제이스탬프 앱 이용이 불가능하며 적립쿠폰 및 이용 내역 등 모든 정보가 사라집니다. 회원탈퇴를 원하시는 경우 제이스탬프 앱 실행 후 하단 메뉴 중 맨 오른쪽의 '더보기 &gt; 오른쪽 상단의 [기어]아이콘 &gt; 사용중인 계정 선택 &gt; 회원탈퇴'를 이용하여 주세요.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>디지털 스탬프는 모든 스마트폰에 인식되나요?</Accordion.Header>
                                    <Accordion.Body>
                                        디지털 스탬프는 국내에 출시 된 대부분은 스마트폰을 지원합니다.단, 10년 이상 된 구 기종의 스마트폰 또는 액정이 깨진 경우는 인식이 안되는 경우가 발생합니다.<br/>이때는 함께 보내드린 “적립형 QR코드”로 적립이 가능합니다<br/>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>교차쿠폰 서비스란 무엇인가요?</Accordion.Header>
                                    <Accordion.Body>
                                        제이스탬프 가맹점과 가맹점이 서로에게 홍보를 해주는 서비스입니다.<br/>예를 들면 카페와 음식점의 사장님들이 각자 고객에게 제공하는 쿠폰을 정하고 카페에 들린 고객들에게 제휴한 음식점의 할인쿠폰을 보내주고, 음식점은 카페의음료교환권을 보내줄 수 있는 서비스입니다.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>스탬프투어란 무엇인가요?</Accordion.Header>
                                    <Accordion.Body>
                                        관광지나 테마파크에서 가면 해봤던 지도에 스탬프를 찍고 선물을 받던 기억나시나요?<br/>아날로그 스탬프투어의 감성을 그대로 살려 모바일 스탬프를 구현했습니다.<br/>카페 스탬프투어, 맛집 스탬프투어, 디저트 스탬프투어 등 다양한 테마와 계절에 맞는 스탬프투어를 제이스탬프 가맹점들과 함께 진행하고 이를 통해 더욱 다양한 고객들이 매장을 방문하게 됩니다.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>저희 고객에게 홍보용 메시지를 전달 할 수 있나요?</Accordion.Header>
                                    <Accordion.Body>
                                        제이스탬프의 푸시 기능을 통해 해당 매장의 고객에게 메시지 전달이 가능합니다.<br/>단, 단순 홍보성 메시지가 아닌 고객에게 혜택을 함께 주실 경우에 메시지 서비스가 가능합니다.<br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Col>
                        </Row>
                    </Accordion>
                    <div className={"channel-banner-wrap"}>
                    <Row>
                        <Col >
                            <div className="d-md-block d-none channel_banner">
                                <a href="https://pf.kakao.com/_KiMyxl">
                                    <Image
                                    src="/img/kakaochanner.jpg"
                                    className="img-fluid mx-auto d-block lazyload kakao_img"
                                    alt="placeholder image"/>
                                </a>

                            </div>
                            <div className=" d-md-none d-block channel_banner">
                                <a href="https://pf.kakao.com/_KiMyxl">
                                    <Image
                                    src="/img/kakaochanner_m.jpg"
                                    className="img-fluid mx-auto d-block lazyload m_kakao_img"
                                    alt="placeholder image"/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    </div>
                </div>
            </div>

            <div className={"download-service-wrap "} >
                <div className={"container default-container"}>
                    <div className={"down-wrpa"}>
                        <Row className={"title-section"}>
                            <Col className={"animated fadeInDown"} data-appear-anim-style="fadeInDown">
                                <h1 className="title text-center">
                                    제이스탬프 다운로드
                                </h1>
                            </Col>
                        </Row>
                        <Row className={"cont-section"}>
                            <Col >
                                <a href="https://play.google.com/store/apps/details?id=com.boxtree.jstamp.activity">
                                    <Image
                                    src="/img/www/android.png"
                                    className="img-fluid float-lg-right app_icon float-sm-right float-right lazyload"
                                    alt="placeholder image" />
                                </a>
                            </Col>
                            <Col >
                                <a href="https://itunes.apple.com/kr/app/apple-store/id1253245929?mt=8">
                                    <Image
                                    src="/img/www/iosapp.png"
                                    className="img-fluid float-lg-none app_icon lazyload" alt="placeholder image" />
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className={"foot-wrap "} >
                <div className={"container default-container"}>
                    <Row className={"cont-section icon-section"}>
                        <Col xs={{span:1, offset: 4}} >
                            <div className="text-center">
                                <a href="https://www.facebook.com/jejustamp/">
                                    {/*<span className="ion ion-social-facebook icon-md social_icon"></span>*/}
                                    <FaFacebookF className={"foot-icon"}/>
                                </a>
                            </div>
                        </Col>
                        <Col xs={1}>
                            <div className="text-center">
                                <a href="https://www.instagram.com/jstamp_official/">
                                    <FaInstagram className={"foot-icon"}/>
                                </a>
                            </div>
                        </Col>
                        <Col xs={1}>
                            <div className="text-center">
                                <a href="https://pf.kakao.com/_KiMyxl">
                                    <RiKakaoTalkFill className={"foot-icon"}/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row className={"cont-section"}>
                        <Col >
                            <h6 className="mg-md text-lg-center footer_title">
                                주식회사 박스트리는 멤버십, 쿠폰, 모바일 선불상품권 등의 쉬운 연결을 위해 헌신하는 O2O 서비스 기업입니다.
                            </h6>
                            <h6 className="mg-md text-lg-center footer_info">
                                주식회사  박스트리 ㅣ 사업자등록번호 447-87-00573 ㅣ 대표이사 지광재 ㅣ 이메일 : cs@boxtree.kr<br/>
                                제주본사  제주특별자치도 제주시 첨단로 213-3, JDC 스마트빌딩 416호 ㅣ 전화 : 064-747-8900 ㅣ FAX : 064-744-7460<br/>
                            </h6>
                            <h6 className="mg-md text-lg-center h6-style footer_copyrighter">
                                © Copyright 2017. All Rights Reserved Boxtree<br/>
                            </h6>
                        </Col>

                    </Row>
                </div>
            </div>

            <div className={"foot-banner-wrap d-md-none scrollToTop"} >
                <div className={"container"}>
                    <Row className={"cont-section"}>
                        <Col className={"first-wrap"}>
                            <h6 >
                                지금 바로<br/>무료상담을 받아보세요
                            </h6>
                        </Col>
                        <Col className={"second-wrap"}>
                            <h6 >
                                <a href="#none" className="btn btn-d btn-md" data-scroll-speed="1000" onClick={handleTopApplyWrap}>도입 문의</a>
                            </h6>
                        </Col>
                    </Row>
                </div>
            </div>

            <LoadingHtml/>
        </div>

    );
};

export default Home;
