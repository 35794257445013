import {ActionResult, HttpMethod} from "../model";
import q  from 'query-string';
export const httpClient = async (props: any) => {
    let url = `${process.env.REACT_APP_API_URL}/${props.url}`;
    let isGet=true;
    const isMultipart:boolean=props.isMultipart ?? false;
    if(props.method!==HttpMethod.GET)
    {
        props.query=null;
        isGet=false;
    }
    if (props.query) {
        url += "?" + props.query;
    }
    let body=null;
    if(props.body){
        if(!isGet) {
            body = isMultipart ? props.body : JSON.stringify(props.body);
        } else{
            body = null;
            url +="?" + q.stringify(props.body);
        }
    }

    console.log(`FETCHING...${url}, isMultipart=${isMultipart}, body=${JSON.stringify(body)}`);
    let options: any = {
        method: props.method,
        mode: 'cors',
        body: body
    };
    console.log(`headers=${JSON.stringify(props.authorization)}`);
    if(!isMultipart){
        options.headers={
            'Accept': 'application/json',
            'Content-Type':'application/json;charset=UTF-8'
        };
    }
    if(props.authorization){
        options.headers.Authorization=props.authorization;
    }
    /*const userAuth=utilService.getUserAuth();
    if(userAuth && userAuth.token!==''){
        options.headers.Authorization=`Bearer ${userAuth.token}`;
    }*/
    console.log(`options headers=${JSON.stringify(options.headers)}`);

    let act: ActionResult = {};
    try {
        const res = await fetch(`${url}`, options);
        let responseOK = res && res.ok;

        if (responseOK) {
            console.log('ajax success');
            act = await res.json();
        } else {
            let errMsg = '';
            switch (res.status) {
                case 403:
                case 401:
                    errMsg = '로그인 세션이 만료되었거나 접근권한이 존재하지 않습니다.\n다시 로그인 하셔야 합니다.';
                    break;
                case 404:
                case 405:
                    errMsg = '존재하지 않는 url입니다.';
                    break;
                case 500:
                    errMsg = '예기치 않은 프로그램 오류가 발생하였습니다.\n고객센터에 문의 바랍니다.';
                    break;
                default:
                    errMsg = `예기치 않은 오류가 발생하였습니다.`;
                    break;
            }
            errMsg +=`(상태코드:${res.status})`;
            act.errorCode = res.status.toString();
            act.result = false;
            act.errorMsg = errMsg;
            console.error(JSON.stringify(act));
        }
    }
    catch (e){
        act.errorCode = '0';
        act.result=false;
        act.errorMsg="서버와 접속이 안되고 있습니다.\n네트워크 상태 또는 고객센터에 문의하세요.";
    }
    return act;
}
