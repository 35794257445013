import React from 'react';
import { Nav, Navbar } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'

const NavBar = () => {
    return (
        <Navbar collapseOnSelect bg="light" expand="md" className={"nav-wrap"} sticky="top">
            <Navbar.Brand href="#home">
                <Image src="/img/www/Artboard_16.png" className={"img-fluid img-style"} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className={"justify-content-end flex-grow-1 pe-3 nav-list-item-wrap"}>
                    <Nav.Link href="#jstamp-desc-wrap" className={'nav-line-st'}>도입효과</Nav.Link>
                    <Nav.Link href="#service-charge-wrap" className={'nav-line-st'}>요금안내</Nav.Link>
                    <Nav.Link href="#service-apply-wrap" className={'nav-line-st'}>도입문의</Nav.Link>
                    <Nav.Link href="#customer-service-wrap" className={'nav-line-st'}>고객센터</Nav.Link>
                    <a href="#" className={"nav-link menus-bt nav-line-st"} onClick={() => alert("V2.0 서비스 준비중 입니다.")}>가맹점 로그인</a>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};

export default NavBar;
