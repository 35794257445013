import { combineReducers } from "redux";
import { TypedUseSelectorHook,useSelector as useReduxSelector } from 'react-redux'
// import board from './board'
import title from './PageTitle'

export const rootReducer = combineReducers({
    // board: board.reducer,
    title: title.reducer
});

//* 스토어의 타입
export type RootState = ReturnType<typeof rootReducer>;

//* 타입 지원되는 커스텀 useSelector 만들기
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;


