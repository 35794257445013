import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './sass/index.scss'
import reportWebVitals from './reportWebVitals'
import App from './shared/App'
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import logger from 'redux-logger'
import { rootReducer } from './store'

const isProduction = process.env.NODE_ENV === 'production'
console.log(`profile=${isProduction}`);
const enhancer = isProduction ? compose(applyMiddleware()) : composeWithDevTools(applyMiddleware(logger))

// 위에서 만든 reducer를 스토어 만들때 넣어줍니다
const store = createStore(rootReducer, enhancer)

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'))
reportWebVitals()
