import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const TermsHtml = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        // e.preventDefault();
        setShow(false);
    }
    const handleShow = (e:any) => {
        e.preventDefault();
        setShow(true);
    }

    return (
        <>
            <p className="mg-md comment">* 문의시 개인정보 수집 및 이용에 동의하신 것으로 간주합니다. [<a href="handleShow" onClick={handleShow}>약관보기</a>]</p>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >온라인 문의 시 개인정보 수집 및 이용에 대한 안내</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        ㈜박스트리(이하 "회사")는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 및 정부가 제정한 개인정보보호지침 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자의 권익 보호에 최선을 다하고 있습니다.<br/>
                        개인정보의 수집 및 이용목적, 수집하는 개인정보의 항목,  개인정보의 보유 및 이용기간을 안내 드립니다.<br/>
                        1. 수집하는 개인정보의 항목 및 수집 방법<br/>
                        ①   ”회사”는 각종 서비스의 상담을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/>
                        (ㄱ)   전화번호<br/>
                        (ㄴ)   이메일<br/>
                        ②   이벤트나 추가 회원정보 수집을 통해 이용자의 관심분야에 대한 세부화 된 항목의 정보는 수집될 수 있습니다. 또한 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br/>
                        (ㄱ)   전화번호<br/>
                        ③   “회사”는 서비스 상담에 필요한 최소한의 개인정보만 수집하며, 이용자의 기본적 인권을 침해할 우려가 있는 민감한 정보(인종, 종교, 사상, 출신지, 본적지, 정치적 성향 및 범죄기록, 건강상태 등)는 수집하지 않습니다. 다만, 이용자가 수집에 동의하시는 경우는 예외로 합니다.<br/>
                        ④   개인정보 수집 방법<br/>
                        “회사”는 다음과 같은 적법하고 공정한 방법으로 개인정보를 수집합니다.<br/>
                        (ㄱ)   홈페이지 내 상담게시판, 이메일<br/>
                        2. 개인정보의 수집 및 이용목적<br/>
                        “회사”는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>
                        ①   고객 상담 접수, 문의 응대<br/>
                        ②   CS 처리 내역 보관<br/>
                        ③   서비스 품질 조사 및 향상<br/>
                        ④   중복 상담 내역 확인<br/>
                        3. 개인정보의 보유 및 이용기간<br/>
                        회사는 상담 완료 후 지체없이 파기<br/>
                        4. 개인정보 파기절차 및 방법<br/>
                        회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한, 수집한 이용자의 개인정보의 수집 및 이용 목적이 달성되거나, 이용자가 회원탈퇴를 요청한 경우 지체 없이 파기하여 향후 어떠한 용도로도 열람 또는 이용할 수 없도록 처리합니다. 단, “4. 개인정보의 보유 및 이용기간 가, 나”와 같은 예외 경우를 두고 있습니다. 회사의 개인정보 파기절치 및 방법은 다음과 같습니다.<br/>
                        ①   파기절차<br/>
                        이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라(5. 보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고 서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/>
                        ②   파기방법<br/>
                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                        5. 개인정보의 기술적/관리적 보호 대책<br/>
                        회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br/>
                        ①   개인정보 암호화<br/>
                        이용자의 주요 개인정보는 암호화하여 저장하고 있으며, 파일 및 전송데이터를 암호화하여 혹시 발생할 수 있는 사고 시라도 이용자의 개인정보가 유출되지 않도록 관리되고 있습니다.<br/>
                        ②   해킹 등에 대비한 대책<br/>
                        회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 24시간 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br/>
                        ③   취급 직원의 최소화 및 교육<br/>
                        회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보취급방침의 준수를 항상 강조하고 있습니다. 또한, 임직원이 이용자의 개인정보를 취급하기 이전에 보안서약서를 통하여 이용자의 개인정보에 대한 정보유출을 사전에 방지하고 관련 사태규정을 마련하여 이에 대한 이행사항 및 준수 여부를 감시하기 위한 내부절차를 마련하고 있습니다.<br/>
                        ④   개인정보보호 전담기구의 운영<br/>
                        사내 개인정보보호전담기구 등을 통하여 개인정보취급방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.<br/>
                        하지만 근본적으로 개인정보의 보안관리에는 이용자 본인의 노력도 중요합니다. Online 서비스는 오직 아이디와 비밀번호를 알고 있는 본인에게만 개인 정보에 접근할 수 있는 권한이 있으며, 비밀번호를 유지하고 관리할 책임 역시도 본인에게 있습니다. 반드시 본인만이 알 수 있는 내용으로 비밀번호를 구성해야 하며, 주민번호의 일부 또는 전화번호 등 타인이 쉽게 도용할 수 있는 비밀번호의 사용을 지양해야 합니다. 또한 공동으로 사용하는 PC에서 회사의 서비스에 접속하여 로그인한 상태에서 다른 사이트로 이동할 경우, 혹은 서비스 이용을 종료하였을 경우에는 반드시 해당 브라우저를 종료하시기 바랍니다. 그렇지 않을 경우, 해당 브라우저를 통해 아이디, 비밀번호, 주민등록번호가 포함된 이용자의 개인정보가 타인에게 손쉽게 유출될 위험이 있습니다. 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.<br/>
                        6. 개인정보 보호책임자 작성<br/>
                        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
                        개인정보 보호책임자<br/>
                        성명: 신현목<br/>
                        소속: 주식회사 박스트리<br/>
                        이메일: mok@boxtree.kr<br/>
                        귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.<br/>
                        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>
                        - 개인정보 보호 종합지원 포털(안전행정부 운영)<br/>
                        www.privacy.go.kr / 02-2100-3343<br/>
                        - 개인정보 침해신고센터 (한국인터넷진흥원 운영)<br/>
                        privacy.kisa.or.kr / (국번없이) 118<br/>
                        - 개인정보보호지원센터 (한국정보화진흥원)<br/>
                        privacy.nia.or.kr / 02-2131-0111<br/>
                        10. 기타<br/>
                        회사가 운영하는 사이트에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "제이스탬프 개인정보취급방침"이 적용되지 않음을 알려 드립니다.<br/>
                        11. 고지의 의무<br/>
                        현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 변경이유 및 내용을 홈페이지의 '공지사항'을 통해 고지할 것입니다. 본 개인정보취급방침의 내용은 수시로 변경될 수 있으므로 사이트를 방문하실 때마다, 이를 확인하시기 바랍니다.<br/>
                        ①   공고일자: 2017년 9월 1일<br/>
                        ②   시행일자: 2017년 9월 1일<br/>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
};

export default TermsHtml;
