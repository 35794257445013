import React from 'react';
//import {Container} from 'react-bootstrap';

const LoadingHtml = (props: any) => {
    //const isParent=props.parent ?? false;
    //const clsName=isParent ? "bloc-lg":"bloc-sm";
    return (
            <div id="page-loading-blocs-notifaction" className="loadingWrap"
                 style={{backgroundImage: "url(/images/pageload-spinner.gif)"}}></div>
    )
}
export default LoadingHtml;
