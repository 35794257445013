import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {httpClient} from '../../service';
import { ActionResult, Combo, HttpMethod } from '../../model'
import { useAsync } from 'react-async'
import { JoinRequest } from '../../model/JoinRequest'
import TermsHtml from '../TermsHtml'

const ApplyHtml = () => {
    const url = 'infos/combos';
    const options = {promiseFn: httpClient, method: HttpMethod.GET, url};
    const {data: act} = useAsync<ActionResult>(options);


    const [validated, setValidated] = useState(false);
    const cities: Combo[] = act?.result ? act.data.cities : [];
    const categories: Combo[] = act?.result ? act.data.categories : [];
    const [model, setModel] = useState<JoinRequest>({});
    const [submitting, setSubmitting] = useState(false);

    const submitForm = async(e:any) => {
        const isValid = e.currentTarget.checkValidity();
        if(!isValid){
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return false;
        }
        e.preventDefault();
        const form = e.target;
        console.log(`form stat=${JSON.stringify(model)}`);
        const url = 'company/introduction';
        const option = {
            url: url,
            method: HttpMethod.POST,
            body: model
        };
        const act = await httpClient(option);
        setSubmitting(false);

        if(act.result){
            alert('문의가 정상적으로 접수되었습니다.');
            const data: JoinRequest = {name:'', tel:''};
            setModel(data);
            form.reset();
        }else{
            alert(`오류:${act.errorMsg}`);
        }
    };

    const handleInputChange = (e: any) => {
        console.log(`name=${e.target.name}, value=${e.target.value}`);
        const data = {...model, [e.target.name]: e.target.value};
        setModel(data);
    }

    return (
        <div className={"apply-wrap"}>
            <Form id={"applyForm"} data-form-type="blocs-form" noValidate validated={validated} onSubmit={submitForm}>
                <Row>
                    <Form.Group as={Col} xs="12">
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="이름 또는 매장명 (필수)"
                            required
                            onChange={handleInputChange} />
                        <Form.Control.Feedback type="invalid">
                            이 항목은 필수항목입니다.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} xs={6}>
                        <Form.Control
                            type="tel"
                            name="tel"
                            placeholder="연락처 (필수)"
                            required
                            onChange={handleInputChange} />
                        <Form.Control.Feedback type="invalid">
                            이 항목은 필수항목입니다.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6}>
                        <Form.Select
                            name="cityId"
                            required
                            onChange={handleInputChange}
                        >
                            <option value="">지역 선택</option>
                            {
                                cities && cities.map(item => (
                                    <option key={item.id} value={item.id}>{item.value}</option>
                                ))
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            이 항목은 필수항목입니다.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} xs={6}>
                        <Form.Select
                            name="categoryId"
                            required
                            onChange={handleInputChange}
                        >
                            <option value="">업종 선택</option>
                            {
                                categories && categories.map(item => (
                                    <option key={item.id} value={item.id}>{item.value}</option>
                                ))
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            이 항목은 필수항목입니다.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6}>
                        <Form.Select
                            name="knowTrack"
                            required
                            onChange={handleInputChange}
                        >
                            <option value="">알게된 경위?</option>
                            <option value="언론홍보">언론홍보</option>
                            <option value="매장 또는 관광지내 홍보물">매장 또는 관광지내 홍보물</option>
                            <option value="지역행사 또는 축제">지역행사 또는 축제</option>
                            <option value="블로그 또는 SNS">블로그 또는 SNS</option>
                            <option value="기타">기타</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            이 항목은 필수항목입니다.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} xs={12}>
                        <Form.Control
                            as="textarea"
                            onChange={handleInputChange}
                            rows={4}
                            cols={50}
                            required
                            name="question"
                            placeholder="궁금하신 점이나 상담이 필요한 내용을 적어주세요">
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <button type="submit"
                                className="bloc-button btn btn-d btn-lg btn-block submit-btn"
                                disabled={submitting}
                        >{submitting ? "전송중.." : "문의하기"}
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TermsHtml />
                    </Col>
                </Row>
            </Form>
        </div>
    )
};

export default ApplyHtml;
